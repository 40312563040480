import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function PrivacyPolicy({ data }) {
  return (
    <Layout>
      <Seo
        title={data.wpPage.seo.title}
        description={data.wpPage.seo.metaDesc}
      />
      <div className="privacy-policy" id="privacy-policy">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10" style={{ textAlign: "center" }}>
              <h1>{parse(data.wpPage.title)}</h1>
              {parse(data.wpPage.content)}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    wpPage(slug: { eq: "privacy-policy" }) {
      seo {
        title
        metaDesc
      }
      id
      title
      content
    }
  }
`
